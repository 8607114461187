@import "bootstrap";

body, html{
	margin:0;
	padding:0;
    width: 100%;
}
body{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00fcfc+0,0e6b87+99 */
background: #00fcfc; /* Old browsers */
background: -moz-linear-gradient(top,  #00fcfc 0%, #0e6b87 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #00fcfc 0%,#0e6b87 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #00fcfc 0%,#0e6b87 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfc', endColorstr='#0e6b87',GradientType=0 ); /* IE6-9 */

color:#0b316d;
}
label{
    font-weight: bold;
}
input.form-control{
    background-color: #00fcfc;
    color:#0b316d;
    -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,4,36,0.61);
    -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,4,36,0.61);
    box-shadow: inset 0px 0px 5px 0px rgba(0,4,36,0.61);
    border:1px solid #5ebff3;
}
.btn-ctm, input.form-control, .custom-file-label{
    background-color: #00fcfc;
    color:#0b316d;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,4,36,0.30);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,4,36,0.30);
    box-shadow: 0px 0px 5px 0px rgba(0,4,36,0.30);
    border: none;
    transition: all .3s linear;
}
.btn-ctm, .custom-file-label::after, .btn-ctm-inactive{
  background-color: #0b316d;
  color:#fff;
}
.custom-file-label{
  height: auto;
}
.btn-ctm:focus, .btn-ctm:hover, .btn-ctm:active, .form-control:focus, .form-control:active, .form-control:hover, .btnActive{
    border: none;
    color:#0b316d!important;
    outline: none;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,4,36,0.85);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,4,36,0.85);
    box-shadow: 0px 0px 5px 0px rgba(0,4,36,0.85);
    background-color: #00fcfc;
}
.form-control:focus, .form-control:active, .form-control:hover{
  background-color: #e9ecef;
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #005a9e;
    opacity: 1; /* Firefox */
  }
  h6.logged{
      color:#004466;
      opacity:0.9;
  }
  .spaceTable label, 
  .spaceTable .separator{
    display: none;
  }
  .spaceTable .row:nth-child(1) label{
    display: block;
  }
  .min-height-100{
      min-height: 768px;
  }
  /* .faLg a{
    font-size: 18px;
    opacity:0.7;
    vertical-align: top;
    color:#004466;
  }
   */
   .faLg svg{
    font-size: 14px;
  }
  .card{
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    background-color: rgba(255, 255, 255, 0.2);
  }
  .spaceTable .row > div{
    padding-left: 0;
    padding-right: 0;
  }
  .spaceTable .form-control{
    box-shadow: none;
    border-radius: 0;
  }
  .spaceTable .form-group{
    margin-bottom: .1rem;
  }
  .spaceTable button {
    font-size: 13px;
    height: 95%;
    margin-top:2%;
    margin-bottom:2%;
  }
  .spaceTable .row:nth-child(1) button{
    height: 50%;
  }
  .btn-name{
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .modal-header button{
    border:0;
    background:transparent
  }
  @media only screen and (max-width: 992px) {
    .spaceTable .separator{
        border-bottom:1px solid #0b316d!important;
        margin-bottom: 25px;
        display: block;
    }
    .spaceTable button {
      font-size: 16px;
      height: auto;
      margin-top:inherit;
      margin-bottom:inherit;
    }
    .btn-ctm-inactive{
      margin-right: 15px;
    }
    .btns{
      margin-top: 10px;
    }
    
  }
  @media only screen and (max-width: 767px) {
    .spaceTable .row > div:nth-child(4) label, 
    .spaceTable .row > div:nth-child(5) label,
    .spaceTable .row > div:nth-child(6) label,
    .d-sm-none{
      display: none;
    }
    .spaceTable .row:nth-child(1) button{
      height: auto;
    }
    .spaceTable .row > div:nth-child(2), .spaceTable .row > div:nth-child(4){
      padding-left: 15px;
    }
    .spaceTable .row > div:nth-child(3), .spaceTable .row > div:nth-child(5){
      padding-right: 15px;
    }
  }